<template>
  <div class="page-cookery-book layout-vertical"
       v-if="cookeryBook"
       style="background-color: white;">
    <div class="padding padding-horizontal-large layout-inflexible font-size-medium">
      <span class="font-color-primary btn-back"
            @click="$router.back()"
      >食谱列表</span>
      <span>/食谱详情</span>
    </div>
    <div class="layout-inflexible">
      <div class="cookery-book-name padding-horizontal-large padding-vertical font-size-large font-weight-bold"
           :class="{
              'is-stopped': cookeryBook.state !== BusinessState.STARTED
           }"
           @click="clickEditCookeryBook"
      >
        <span v-if="cookeryBook.state !== BusinessState.STARTED">[已停止]</span>
        <span>{{ cookeryBook.name }}</span>
        <span v-if="weekNum != null" class="font-weight-bold"> · 第{{ weekNum + 1 }}周餐单</span>
        <span class="margin-left-small font-color-placeholder"><i class="fas fa-pen-alt"></i></span>
      </div>
      <div></div>
      <div class="cookery-book-name padding-horizontal-large padding-vertical font-size-medium font-color-secondary"
           v-if="cookeryBook.message"
           @click="clickEditCookeryBook">
        <span class="font-color-placeholder"><i class="fas fa-quote-right"></i> </span>
        <span class="margin-left-small">{{ cookeryBook.message }}</span>
        <span class="margin-left-small font-color-placeholder"><i class="fas fa-pen-alt"></i></span>
      </div>
    </div>
    <div class="layout-flexible margin-top-small" style="overflow: hidden; max-width: calc(100vw - 420px);">
        <ys-infinite-scroll-view
            style="height: 100%;"
        >
          <div class="weekly-record-list-container" ref="weeklyRecordListContainer" @wheel="handleMouseWheel">
            <div class="weekly-record-list layout-horizontal margin-left-small margin-horizontal-large">
              <diet-weekly-record
                  v-for="weeklyRecord in dietWeeklyRecordList"
                  :key="weeklyRecord.weekNum"
                  :value="weeklyRecord"
                  :is-active="weeklyRecord.weekNum === weekNum"
                  @click.native="clickWeeklyRecord(weeklyRecord.weekNum)"
              ></diet-weekly-record>
            </div>
          </div>
          <diet-list-panel
              class="margin-top margin-right"
              :organization-id="organizationId"
              :cookery-book="cookeryBook"
              :week-num="weekNum"
              @input="handleDietInput"
          ></diet-list-panel>
        </ys-infinite-scroll-view>
    </div>
    <cookery-book-edit-dialog
        :cookery-book="cookeryBook"
        :visible.sync="cookeryBookEditDialogVisible"
        @confirm="handleCookeryBookUpdate"
    ></cookery-book-edit-dialog>
  </div>
</template>

<script>
import httpapi from "@/assets/javascript/httpapi";
import {CookeryBookUtils} from "@/assets/javascript/cookery-book-utils";
import DietWeeklyRecord from "@/pages/cookerybook/component/DietWeeklyRecord";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import DietListPanel from "@/pages/cookerybook/component/DietListPanel";
import BusinessState from "@/assets/javascript/business-state";
import CookeryBookEditDialog from "@/pages/cookerybook/component/CookeryBookEditDialog";

export default {
  name: "CookerBook",
  components: {CookeryBookEditDialog, DietListPanel, YsInfiniteScrollView, DietWeeklyRecord},
  mixins: [httpapi],
  props: {
    organizationId: Number,
    userId: Number,
  },
  data() {
    return {

      /**
       * 常量
       */
      BusinessState: BusinessState,

      /**
       * api同步参数
       */
      busyLoadingCode: 0,

      /**
       * 用户信息
       */
      user: null,

      /**
       * 食谱信息
       */
      cookeryBook: null,

      /**
       * 餐单周记录
       */
      dietWeeklyRecordList: [],

      /**
       * 当前周数
       */
      weekNum: null,

      /**
       * 刷新时间
       */
      refreshTimeoutId: null,

      /**
       * 编辑食谱
       */
      cookeryBookEditDialogVisible: false,
    }
  },
  computed: {
    inputParams() {
      const {userId, organizationId} = this;
      return {
        userId,
        organizationId,
      }
    }
  },
  watch: {
    inputParams: {
      handler() {
        this.loadingCode++;
        this.weekNum = null;
        this.cookeryBook = null;
      },
      immediate: true,
    }
  },
  mounted() {
    let cookeryBookId = this.$route.query.id;
    if (cookeryBookId) {
      this.loadCookeryBook(cookeryBookId);
    } else {
      this.loadUser();
    }
  },
  methods: {
    clickWeeklyRecord(weekNum) {
      this.weekNum = weekNum;
    },
    clickEditCookeryBook() {
      console.log('click...');
      this.cookeryBookEditDialogVisible = true;
    },
    handleDietInput: function () {
      if (this.refreshTimeoutId) {
        clearTimeout(this.refreshTimeoutId);
      }
      this.refreshTimeoutId = setTimeout(() => {
        this.refreshCookeryBook();
        this.refreshTimeoutId = null;
      }, 500);
    },
    handleCookeryBookUpdate(cookeryBook) {
      this.cookeryBook = cookeryBook;
    },
    handleMouseWheel(event) {
      let weeklyRecordListContainer = this.$refs.weeklyRecordListContainer;
      weeklyRecordListContainer.scrollLeft += event.deltaY;
    },
    isBusy() {
      return this.busyLoadingCode === this.loadingCode;
    },
    loadCookeryBook(id) {
      if (this.isBusy()) return;
      this.busyLoadingCode = this.loadingCode;
      this.$reqGet({
        path: `/cookery_book/${id}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let cookeryBook = res.data;
            this.cookeryBook = cookeryBook;
            this.dietWeeklyRecordList = this.cookeryBook.dietWeeklyRecordList;
            if (this.weekNum === null) {
              this.weekNum = this.dietWeeklyRecordList.length - 1;
              setTimeout(()=>{
                  this.$refs.weeklyRecordListContainer.scrollLeft = 999999;
              }, 100);
            }
            this.dietWeeklyRecordList.push(CookeryBookUtils.defaultDiet(this.dietWeeklyRecordList.length));
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.busyLoadingCode = 0);
    },
    refreshCookeryBook() {
      let cookeryBookId = this.cookeryBook.id;
      this.$reqGet({
        path: `/cookery_book/${cookeryBookId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let cookeryBook = res.data;
            this.dietWeeklyRecordList = cookeryBook.dietWeeklyRecordList;
            if (this.weekNum === null) this.weekNum = this.dietWeeklyRecordList.length - 1;
            this.dietWeeklyRecordList.push(CookeryBookUtils.defaultDiet(this.dietWeeklyRecordList.length));
          })
    },
    loadUser() {
      this.$reqGet({
        path: `/user/${this.userId}/${this.organizationId}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            let user = res.data;
            let cookeryBook = CookeryBookUtils.defaultCookeryBook();
            let name = (user.name.length > 10 ? user.name.substr(0, 10) : user.name) + '的营养食谱';
            cookeryBook.name = name;
            cookeryBook.state = BusinessState.STARTED;
            cookeryBook.userId = this.userId;
            cookeryBook.organizationId = this.organizationId;
            this.cookeryBook = cookeryBook;
            this.weekNum = 0;
            this.dietWeeklyRecordList = [CookeryBookUtils.defaultDiet(0)];
          })
          .catch(() => this.$message.error('加载失败'))
          .complete(() => this.busyLoadingCode = 0);


    },
  }
}
</script>

<style scoped>

.page-cookery-book {
  background-color: white;
  overflow: hidden;
}

.btn-back {
  cursor: pointer;
}

.cookery-book-name {
  cursor: pointer;
  border-radius: 8px;
  display: inline-block;
}

.cookery-book-name:hover {
  background-color: #f1f1f1;
}

.cookery-book-name:hover .fa-pen-alt {
  color: #ff5500;
}

.is-stopped {
  color: #bec0c0;
  text-decoration: line-through;
}

.weekly-record-list-container {
  overflow-x: scroll;
  max-width: 100%;
}

.weekly-record-list-container::-webkit-scrollbar {
  height: 6px;
}

.weekly-record-list-container::-webkit-scrollbar-thumb {
  height: 6px;
  border-radius: 100px;
  background-color: #409EFF;
  visibility: hidden;
}

.weekly-record-list-container:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}


.weekly-record-list {
  width: max-content;
}

</style>